//App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Connexion from './components/auth/Connexion';
import Inscription from './components/auth/Inscription';
import Dashboard from './components/dashboard/Dashboard';
import Products from './components/dashboard/Products';
import Routine from './components/dashboard/Routine';
import Profile from './components/dashboard/Profil';
import Messagerie from './components/dashboard/Messagerie';
import Discussion from "./components/dashboard/Discussion";



function App() {
    return (
        <Router>
            <div>
                {/* <Header/> */}
                <Routes>
                    <Route path="/" element={<Connexion onLogin={(email, password) => console.log(email, password)} />} />
                    <Route path="/register" element={<Inscription onRegister={(email, password) => console.log(email, password)} />} />
                    <Route path="/dashboard" element={<Dashboard/>} />
                    <Route path="/products" element={<Products/>} />
                    <Route path="/routine" element={<Routine/>} />
                    <Route path="/profile" element={<Profile/>} />
                    <Route path="/discussion/:id" element={<Discussion />} />

                    <Route path="/profile" element={<Messagerie/>} />
                </Routes>
                {/* <Footer/> */}
            </div>
        </Router>
    );
}

export default App;
