//MessagesComponent.js

import React from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Typography, Paper } from '@material-ui/core';

const MessagePage = () => {
    return (
        <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px' }}>
            <Paper style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <List>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar alt="John Doe" src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                            primary="John Doe"
                            secondary={
                                <>
                                    <Typography component="span" variant="body2" color="textPrimary">
                                        Hey, how are you?
                                    </Typography>
                                    <br />
                                    <Typography component="span" variant="body2" color="textSecondary">
                                        10:00 AM
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    {/* Add more messages here */}
                    {Array.from({ length: 20 }).map((_, index) => (
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Jane Smith" src="/static/images/avatar/2.jpg" />
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Jane Smith"
                                    secondary={
                                        <>
                                            <Typography component="span" variant="body2" color="textPrimary">
                                                I'm good, thank you!
                                            </Typography>
                                            <br />
                                            <Typography component="span" variant="body2" color="textSecondary">
                                                10:05 AM
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </React.Fragment>
                    ))}
                </List>
            </Paper>
        </div>
    );
};

export default MessagePage;
