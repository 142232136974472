//Dashboard.js

import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box, CssBaseline, AppBar, Toolbar, List, Typography, ListItem, ListItemIcon,
    ListItemText, IconButton, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent,
    TextField, DialogActions, Drawer, Avatar, Menu, MenuItem, TablePagination, Fab
} from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MessageIcon from '@mui/icons-material/Message';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GET_ALL_USERS, DELETE_USER_MUTATION, UPDATE_USER_MUTATION, CREATE_USER_MUTATION} from '../../graphql/mutations';
import { ME_QUERY } from '../../graphql/queries';
import Messagerie from './Messagerie';
import logo from "../../assets/logo.png";
import '../../styles/Dashboard.css';
import RoutineDashboard from './Routine';
import AddIcon from '@mui/icons-material/Add';
import ProductDashboard from './Products';
import Footer from '../common/Footer';
import AppsUsage from './Apps-usage';
import Statistique from './Statistique';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InsightsIcon from '@mui/icons-material/Insights';

const theme = createTheme({
    palette: {
        primary: {
            light: '#63a4fff',
            main: '#6A0D47',
            dark: '#F885CC',
            contrastText: '#ecfad8',
        },
        secondary: {
            light: '#F885CC',
            main: '#F885CC',
            dark: '#c49000',
            contrastText: '#6d4c41',
        },
        background: {
            default: '#f4f5f7',
        },
    },
});

const drawerWidth = 240;

const Dashboard = () => {
    const navigate = useNavigate();
    const { loading, error, data, refetch } = useQuery(GET_ALL_USERS);
    const [deleteUser] = useMutation(DELETE_USER_MUTATION, { onCompleted: () => refetch() });
    const [updateUser] = useMutation(UPDATE_USER_MUTATION, { onCompleted: () => refetch() });
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentUser, setCurrentUser] = useState({ id: '', firstName: '', lastName: '', email: '', profileImage: '' });
    const [anchorEl, setAnchorEl] = useState(null);
    // const { data: userData, loading: userLoading, error: userError } = useQuery(ME_QUERY);
    const [createUser] = useMutation(CREATE_USER_MUTATION, {
        onCompleted: () => {
            refetch();
            setOpenCreateDialog(false);
            setNewUser({ firstName: '', lastName: '', email: '', mdp: '' });
        }
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
    const { data: userData, loading: userLoading, error: userError } = useQuery(ME_QUERY);

    const [activeContent, setActiveContent] = useState('users'); // 'users', 'products', 'routine'
    
    const [newUser, setNewUser] = useState({ firstName: '', lastName: '', email: '', mdp: '' });
    const handleShowUsers = () => setActiveContent('users');
    const handleShowProducts = () => setActiveContent('products');
    const handleShowRoutines = () => setActiveContent('routine');
    const handleShowStats = () => setActiveContent('stats');
    const handleShowAppUsage = () => setActiveContent('appUsage');
    const handleShowMessages = () => setActiveContent('messages');
    const handleNewUserChange = (e) => setNewUser({ ...newUser, [e.target.name]: e.target.value });
    const handleOpenCreateDialog = () => setOpenCreateDialog(true);
    const handleCloseCreateDialog = () => setOpenCreateDialog(false);
    const handleCreateUser = async () => {
        await createUser({ variables: { ...newUser } });
        refetch();
        resetForm(); 
    };

    const resetForm = () => {
        setNewUser({ firstName: '', lastName: '', email: '', mdp: '' });
        setOpenCreateDialog(false);
    };
    const isMenuOpen = Boolean(anchorEl);
    const filteredUsers = data?.getAllUsers.filter((user) =>
        user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

    const userManagement = () => {
        return (
            <>
                    <Toolbar/>
                <div className='Title'>
                    <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                        <h2>Liste des utilisateurs</h2>
                        <Fab color="primary" aria-label="add" onClick={handleOpenCreateDialog}>
                            <AddIcon/>
                        </Fab>
                    </Box>
                </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: 'center',
                        width: '90%',
                        margin: 'auto',
                        paddingBottom: '15px',
                        paddingTop: '35px'
                    }}>
                        <TextField
                            id="search"
                            label="Filtrer par nom ou email"
                            type="search"
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{mb: 2}}
                        />
                    </div>
                    <div className="dashboard-container">

                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Photo de Profil</TableCell>
                                        <TableCell>Nom</TableCell>
                                        <TableCell>Prénom</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell>{user.id}</TableCell>
                                            <TableCell>
                                                <Avatar src={user.profileImage || "/default-profile.png"}
                                                        alt={`${user.firstName} ${user.lastName} ${user.profileImage}`}/>
                                            </TableCell>
                                            <TableCell>{user.firstName}</TableCell>
                                            <TableCell>{user.lastName}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>
                                                <IconButton color="primary" onClick={() => handleOpenDialog(user)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton color="secondary" onClick={() => handleDelete(user.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={filteredUsers.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, newPage) => setPage(newPage)}
                            onRowsPerPageChange={(event) => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </div>
                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                        <DialogTitle>Mise à jour de l'utilisateur</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="firstName"
                                label="Prénom"
                                type="text"
                                fullWidth
                                name="firstName"
                                value={currentUser.firstName}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                id="lastName"
                                label="Nom"
                                type="text"
                                fullWidth
                                name="lastName"
                                value={currentUser.lastName}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                id="email"
                                label="Email"
                                type="email"
                                fullWidth
                                name="email"
                                value={currentUser.email}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                id="profileImage"
                                label="Image de Profil"
                                type="text"
                                fullWidth
                                name="profileImage"
                                value={currentUser.profileImage}
                                onChange={handleChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog}>Annuler</Button>
                            <Button onClick={handleUpdate}>Mettre à jour</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog}>
                        <DialogTitle>Créer un nouvel utilisateur</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="firstName-create"
                                label="Prénom"
                                type="text"
                                fullWidth
                                name="firstName"
                                value={newUser.firstName}
                                onChange={handleNewUserChange}
                            />
                            <TextField
                                margin="dense"
                                id="lastName-create"
                                label="Nom"
                                type="text"
                                fullWidth
                                name="lastName"
                                value={newUser.lastName}
                                onChange={handleNewUserChange}
                            />
                            <TextField
                                margin="dense"
                                id="email-create"
                                label="Email"
                                type="email"
                                fullWidth
                                name="email"
                                value={newUser.email}
                                onChange={handleNewUserChange}
                            />
                            <TextField
                                margin="dense"
                                id="mdp-create"
                                label="Mot de Passe"
                                type="password"
                                fullWidth
                                name="mdp"
                                value={newUser.mdp}
                                onChange={handleNewUserChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCreateDialog}>Annuler</Button>
                            <Button onClick={handleCreateUser}>Créer</Button>
                        </DialogActions>
                    </Dialog>
                </>
                )
                }
    

    const [showMessages, setShowMessages] = useState(false);
    const [selectedConversation, setSelectedConversation] = useState(null);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleMenuClose();
        localStorage.removeItem('token');
        navigate('/');
    };

    const handleDelete = async (id) => {
        console.log("Deleting user with id:", id);
        await deleteUser({ variables: { id } });
    };

    const handleOpenDialog = (user) => {
        console.log("Opening dialog for user:", user);
        setCurrentUser(user);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleUpdate = async () => {
        try {
            console.log("Updating user with data:", currentUser);
            await updateUser({ variables: { ...currentUser },
                refetchQueries: [{ query: GET_ALL_USERS }],
            })
            setOpenDialog(false);
        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'utilisateur:", error);
        }
    };

    const handleChange = (e) => {
        console.log("Changing current user data:", { [e.target.name]: e.target.value });
        setCurrentUser({ ...currentUser, [e.target.name]: e.target.value });
    };

    const menuId = 'primary-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => navigate('/profile')}>Profil</MenuItem>
            <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
        </Menu>
    );

    if (loading) {
        console.log("Loading users...");
        return <p>Loading...</p>;
    }

    if (error) {
        console.log("Error loading users:", error);
        return <p>Error :(</p>;
    }

    let ContentComponent;
    switch (activeContent) {
        case 'users':
            ContentComponent = userManagement;
            break;
        case 'products':
            ContentComponent = ProductDashboard;
            break;
        case 'routine':
            ContentComponent = RoutineDashboard;
            break;
        case 'stats':
            ContentComponent = Statistique;
            break;
        case 'appUsage':
            ContentComponent = AppsUsage;
            break;
        case 'messages':
            ContentComponent = Messagerie;
            break;
        default:
            ContentComponent = userManagement;
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
                    <Toolbar>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                            Mon Dashboard
                        </Typography>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <Avatar src={userData?.me?.user?.profileImage || "/default-profile.png"} />

                        </IconButton>
                    </Toolbar>
                </AppBar>
                {renderMenu}
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                >
                    <Toolbar />
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={logo} alt="Logo Groomed Hair" className="logo" style={{ width: '150px', height: 'auto' }} />
                    </Box>
                    <Box sx={{ overflow: 'auto' }}>
                        <List>
                            <ListItem  onClick={handleShowUsers}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Gestion des utilisateurs" />
                            </ListItem>
                            <ListItem onClick={handleShowProducts}>
                                <ListItemIcon>
                                    <ShoppingBasketIcon />
                                </ListItemIcon>
                                <ListItemText primary="Gestion des produits" />
                            </ListItem>
                            <ListItem button onClick={handleShowRoutines}>
                                <ListItemIcon>
                                    <FitnessCenterIcon />
                                </ListItemIcon>
                                <ListItemText primary="Gestion des routines" />
                            </ListItem>
                            <ListItem button onClick={handleShowStats}>
                                <ListItemIcon>
                                    <QueryStatsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Statistiques" />
                            </ListItem>
                            <ListItem button onClick={handleShowAppUsage}>
                                <ListItemIcon>
                                    <InsightsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Apps-usage" />
                            </ListItem>
                            <ListItem button onClick={handleShowMessages}>
                                <ListItemIcon>
                                    <MessageIcon />
                                </ListItemIcon>
                                <ListItemText primary="Messagerie" />
                            </ListItem>
                        </List>
                    </Box>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <ContentComponent />
                    {/* <Messagerie
                            onSelectConversation={(conversation) => {
                                console.log("Selected conversation:", conversation);
                                setSelectedConversation(conversation);
                                setShowMessages(false);
                            }}
                        /> */}
                    {/* {!showMessages ? (
                        <ContentComponent />
                    ) : (
                        <Messagerie
                            onSelectConversation={(conversation) => {
                                console.log("Selected conversation:", conversation);
                                setSelectedConversation(conversation);
                                setShowMessages(false);
                            }}
                        />
                    )} */}
                </Box>
            </Box>
            <Footer />
        </ThemeProvider>
    );
}

export default Dashboard;
