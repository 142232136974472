// dashboard/src/components/auth/Inscription.js

import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_USER_MUTATION } from '../../graphql/mutations';
import '../../styles/Inscription.css';
import { Link, useNavigate } from 'react-router-dom';

const Inscription = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('moderateur');
    const navigate = useNavigate();

    const [createUser, { loading, error }] = useMutation(CREATE_USER_MUTATION, {
        onCompleted: (data) => {
            navigate('/');
        },
    });

    useEffect(() => {
        document.title = 'Inscription';
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        createUser({ variables: { firstName, lastName, email, mdp: password, role } });
    };

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    return (
        <div className="inscription-container">
            <form onSubmit={handleSubmit} className="inscription-form">
                <h2 className="inscription-title">Inscription</h2>
                {loading && <p>Chargement...</p>}
                {error && <p>Erreur : {error.message}</p>}
                <div>
                    <label>Prénom:</label>
                    <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Nom:</label>
                    <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Mot de passe:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="role-selection">
                    <label>
                        <input
                            type="radio"
                            name="role"
                            value="admin"
                            checked={role === 'admin'}
                            onChange={handleRoleChange}
                        />
                        Administrateur
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="role"
                            value="moderateur"
                            checked={role === 'moderateur'}
                            onChange={handleRoleChange}
                        />
                        Modérateur
                    </label>
                </div>
                <button type="submit" disabled={loading}>
                    S'inscrire
                </button>
                <div className="link-container">
                    <Link to="/" className="inscription-link">
                        Déjà inscrit ? Se connecter ici
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default Inscription;
