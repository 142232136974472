import React, { useState, useEffect} from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { ME_QUERY } from './../../graphql/queries';
import { UPDATE_USER_MUTATION } from './../../graphql/mutations';
import { CircularProgress, } from '@material-ui/core';
import '../../styles/Profil.css';
import logo from "../../assets/logo.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, AppBar, Toolbar, List, Typography, ListItem, ListItemIcon,
    ListItemText, IconButton,  Button, TextField, Drawer, Avatar } from '@mui/material';
import MessagesComponent from './MessagesComponent';
import PrivacyPage from './PrivacyPage';
import PersonIcon from '@mui/icons-material/Person';
import TextsmsIcon from '@mui/icons-material/Textsms';
import ExtensionIcon from '@mui/icons-material/Extension';
import LogoutIcon from '@mui/icons-material/Logout';

// function TabPanel(props) {
//     const { children, value, index, ...other } = props;
//     return (
//         <div role="tabpanel" hidden={value !== index} {...other}>
//             {value === index && <Box p={3}>{children}</Box>}
//         </div>
//     );
// }

const UserProfile = () => {
    const { data, loading, error } = useQuery(ME_QUERY, { fetchPolicy: 'network-only' });
    const [updateUser, { loading: updating, error: updateError }] = useMutation(UPDATE_USER_MUTATION);
    // const [tabIndex, setTabIndex] = useState(0);
    const [user, setUser] = useState({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        profileImage: '',
    });
    const navigate = useNavigate();
    const [profileImageFile, setProfileImageFile] = useState(null);
    const fileInputRef = React.createRef();
    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };
    useEffect(() => {
        if (data && data.me && data.me.user) {
            setUser(data.me.user);
        }
    }, [data]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value });
    };

    // const handleTabChange = (event, newValue) => {
    //     setTabIndex(newValue);
    // };

    const handleShowProfil = () => setActiveContent('profil');
    const handleShowMessages = () => setActiveContent('messages');
    const handleShowConfidentialite = () => setActiveContent('confidentialité');
    const [activeContent, setActiveContent] = useState('profil');

    // Simuler une fonction de téléchargement d'image
    const uploadImageToYourService = async (file) => {
        // Simulez le téléchargement d'image ici
        // Dans la pratique, vous téléchargeriez le fichier à un service et retourneriez l'URL
        return 'URL_de_votre_image_téléchargée';
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (profileImageFile) {
            try {
                // Téléchargez l'image et obtenez l'URL
                const imageUrl = await uploadImageToYourService(profileImageFile);

                await updateUser({
                    variables: {
                        id: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        profileImage: imageUrl,
                    },
                });

                alert('Profil mis à jour avec succès !');
            } catch (err) {
                console.error(err);
                alert('Erreur lors de la mise à jour du profil. Veuillez réessayer.');
            }
        } else {
            alert('Veuillez sélectionner une image à télécharger.');
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setProfileImageFile(file);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleClickOnDropzone = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImageFile(file);
        }
    };
    const drawerWidth = 270;
    const menuId = 'primary-account-menu';

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Erreur : {error.message}</Typography>;

    const UserProfil = () => {
        return (
            <form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginTop: '6%', marginBottom: '7%'}}>
                <div style={{ width: '300px', marginLeft: '30%'}}>
                    <input type="file" name="profileImage" onChange={handleFileInputChange} style={{ display: 'none' }} ref={fileInputRef} />
                    <TextField
                        label="Prénom"
                        name="firstName"
                        value={user.firstName}
                        onChange={handleChange}
                        variant="standard"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Nom"
                        name="lastName"
                        value={user.lastName}
                        onChange={handleChange}
                        variant="standard"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                        variant="standard"
                        fullWidth
                        margin="normal"
                    />
                    <Button type="submit" color="primary" variant="contained" disabled={updating} fullWidth>
                        Mettre à jour le profil
                    </Button>
                    {updating && <CircularProgress size={24} />}
                    {updateError && <Typography color="error">{updateError.message}</Typography>}
                </div>
                <div
                    className="dropzone"
                    onClick={handleClickOnDropzone}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    style={{ border: '2px dashed #ccc', borderRadius: '5px', padding: '20px', textAlign: 'center', width: '300px', margin: 'auto' }}
                >
                    {profileImageFile ? (
                        <img src={URL.createObjectURL(profileImageFile)} alt="Profile" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                    ) : (
                        <img src={user.profileImage || '/default-profile-image.jpg'} alt="Profile" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                    )}
                    <p>Changer la photo de profil</p>
                </div>
            </form>
        )
    }

    let ContentComponent;
    switch (activeContent) {
        case 'profil':
            ContentComponent = UserProfil;
            break;
        case 'messages':
            ContentComponent = MessagesComponent;
            break;
        case 'confidentialité':
            ContentComponent = PrivacyPage;
            break;
        default:
            ContentComponent = UserProfil; // Fallback au cas où
    }

    return (
        <div>
            <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, backgroundColor: '#6A0D47'}}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        Mon profil utilisateur
                    </Typography>
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <img src={logo} alt="Logo Groomed Hair" className="logo" style={{ width: '100px', height: 'auto' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <IconButton color="#F50E85" onClick={() => navigate('/dashboard')}>
                        <ArrowBackIcon />
                    </IconButton>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Avatar
                        alt="Remy Sharp"
                        src={data?.me?.user?.profileImage}
                        sx={{ width: 150, height: 150 }}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid rgb(229 231 235)', marginLeft: '25px', marginRight: '25px' }}>
                    <h3>{data?.me?.user?.firstName}</h3>
                </Box>

                <Box sx={{ overflow: 'auto', display: 'flex', justifyContent: 'center' }}>
                    <List>
                        <ListItem onClick={handleShowProfil}>
                            <ListItemIcon>
                                <PersonIcon className='buttonIcon' />
                            </ListItemIcon>
                            <ListItemText primary="Mon compte" />
                        </ListItem>
                        <ListItem onClick={handleShowMessages}>
                            <ListItemIcon>
                                <TextsmsIcon className='buttonIcon'/>
                            </ListItemIcon>
                            <ListItemText primary="Mes Messages" />
                        </ListItem>
                        <ListItem onClick={handleShowConfidentialite} >
                            <ListItemIcon>
                                <ExtensionIcon className='buttonIcon' />
                            </ListItemIcon>
                            <ListItemText primary="Confidentialité" />
                        </ListItem>
                        <ListItem button onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutIcon className='buttonIcon' />
                            </ListItemIcon>
                                <ListItemText primary="Déconnexion" />
                            </ListItem>

                    </List>
                </Box>
            </Drawer>
            <Toolbar />
            <Box component="main" sx={{ flexGrow: 1, p: 6}}>
                <ContentComponent />
            </Box>

        </div>
    );
};

export default UserProfile;
