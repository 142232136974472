// dashboard/src/components/auth/Connexion.js

import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { LOGIN_MUTATION } from '../../graphql/mutations';
import '../../styles/Connexion.css';
import { useNavigate } from 'react-router-dom';
import Header from '../common/Header';
import Footer from '../common/Footer';

const Connexion = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const [login, { loading, error }] = useMutation(LOGIN_MUTATION, {
        onCompleted: (data) => {
            localStorage.setItem('token', data.login.token);
            navigate('/dashboard');
        },
    });

    useEffect(() => {
        document.title = 'Connexion';
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        login({ variables: { email, mdp: password } });
    };

    return (
        <div className="connexion-container">
            <Header />
            <form onSubmit={handleSubmit} className="connexion-form">
                <h2 className="connexion-title">Connexion</h2>
                {loading && <p>Chargement...</p>}
                {error && <p>Erreur : {error.message}</p>}
                <div className="form-group">
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Mot de passe:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={loading}>
                    Se connecter
                </button>
                {/* <div className="link-container">
                    <Link to="/register" className="inscription-link">
                        Pas encore inscrit ? S'inscrire ici
                    </Link>
                </div> */}
            </form>
            <Footer />
        </div>
    );
};

export default Connexion;
