import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useLocation } from 'react-router-dom';
import { Paper, Box, Typography, CircularProgress, List, TextField, Button } from '@mui/material';
import { MESSAGES_QUERY, CREATE_MESSAGE_MUTATION, ME_QUERY } from '../../graphql/queries';
import { styled, useTheme } from '@mui/material';

const MessageContainer = styled(Box)(({ theme, alignRight, isAdminMessage }) => ({
    display: 'flex',
    justifyContent: alignRight ? 'flex-end' : 'flex-start',
    marginBottom: theme.spacing(2),
    backgroundColor: isAdminMessage ? '#d1e7dd' : (alignRight ? theme.palette.primary.light : '#f0f0f0'),
    color: isAdminMessage ? theme.palette.text.primary : (alignRight ? theme.palette.primary.contrastText : theme.palette.text.primary),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
}));

const Discussion = () => {
    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const { conversationId, userName } = location.state || {};
    const [newMessage, setNewMessage] = useState('');

    // Fetch current user data
    const { data: userData, loading: userLoading, error: userError } = useQuery(ME_QUERY);
    const currentUser = userData?.me?.user;
    const currentUserMongoId = currentUser?.mongoId;

    // Fetch messages for the current conversation
    const { loading, error, data, refetch } = useQuery(MESSAGES_QUERY, {
        variables: { conversationId },
        skip: !conversationId,
    });

    const [createMessage] = useMutation(CREATE_MESSAGE_MUTATION, {
        onCompleted: () => {
            refetch();
            setNewMessage('');
        }
    });

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            createMessage({
                variables: { conversationId, content: newMessage },
            });
        }
    };

    useEffect(() => {
        if (conversationId) {
            refetch();
        }
    }, [conversationId, refetch]);

    if (loading || userLoading) {
        return <CircularProgress />;
    }

    if (error || userError) {
        return <Typography>Error loading messages</Typography>;
    }

    if (!data || !data.messages) {
        return <Typography>No messages found.</Typography>;
    }

    return (
        <Paper elevation={3} sx={{ padding: 2, height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6">
                    Discussion avec {userName}
                </Typography>
            </Box>

            <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
                {data.messages.map((message) => {
                    const isMine = message.sender?.mongoId === currentUser?.mongoId;
                    const isAdminMessage = message.sender?.role === 'admin';

                    return (
                        <MessageContainer key={message.id} alignRight={isMine} isAdminMessage={isAdminMessage}>
                            <Typography variant="body2" gutterBottom>
                                {message.content}
                            </Typography>
                            <Typography variant="caption" sx={{ display: 'block', textAlign: isMine ? 'right' : 'left' }}>
                                {new Date(parseInt(message.timestamp)).toLocaleString()}
                            </Typography>
                        </MessageContainer>
                    );
                })}
            </List>

            <Box sx={{ display: 'flex', marginTop: 2 }}>
                <TextField
                    fullWidth
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    label="Tapez votre message"
                    variant="outlined"
                />
                <Button variant="contained" color="primary" onClick={handleSendMessage} sx={{ marginLeft: 2 }}>
                    Envoyer
                </Button>
            </Box>
        </Paper>
    );
};

export default Discussion;