import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_PRODUCTS, CREATE_PRODUCT, UPDATE_PRODUCT, DELETE_PRODUCT } from '../../graphql/mutations';
import {
    Box, CssBaseline, AppBar, Toolbar, List, Typography, ListItem, ListItemIcon,
    ListItemText, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent,
    TextField, DialogActions, Drawer, Avatar, Menu, MenuItem, TablePagination, Fab
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import '../../styles/Products.css';
import Footer from '../common/Footer';
// import Header from '../common/Header';

const ProductDashboard = () => {
    const { loading, error, data, refetch } = useQuery(GET_ALL_PRODUCTS);
    // const [newProduct, setNewProduct] = useState({ name: '', price: '', quantite: '', image: '', categorie: '' });
    // const handleNewProductChange = (e) => setNewProduct({ ...newProduct, [e.target.name]: e.target.value });
    const [createProduct] = useMutation(CREATE_PRODUCT);

    const [updateProduct] = useMutation(UPDATE_PRODUCT);
    const [deleteProduct] = useMutation(DELETE_PRODUCT);

    const [formData, setFormData] = useState({ name: '', price: '', quantite: '', image: '', categorie: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [editingId, setEditingId] = useState('');

    const [currentPage, setCurrentPage] = useState(0); // Zero-based page index
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const handleOpenCreateDialog = () => setOpenCreateDialog(true);
    const handleCloseCreateDialog = () => {
        resetForm();
    };



    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const resetForm = () => {
        setFormData({ name: '', price: '', quantite: '', image: '', categorie: '' });
        setIsEditing(false);
        setEditingId('');
        setOpenCreateDialog(false);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const { name, price, quantite, image, categorie } = formData;
        try {
            if (isEditing) {
                await updateProduct({
                    variables: {
                        id: editingId,
                        name,
                        price: parseFloat(price),
                        quantite: parseInt(quantite, 10),
                        image,
                        categorie,
                    },
                });
            } else {
                await createProduct({
                    variables: {
                        name,
                        price: parseFloat(price),
                        quantite: parseInt(quantite, 10),
                        image,
                        categorie,
                    },
                });
            }
            refetch();
            resetForm();  // Réinitialisez et fermez le dialogue ici
        } catch (error) {
            console.error("Error handling the product:", error);
        }
    };


    const handleUpdate = (product) => {
        setIsEditing(true);
        setEditingId(product.id);
        setFormData({ name: product.name, price: product.price.toString(), quantite: product.quantite.toString(), image: product.image, categorie: product.categorie || '', });
        setOpenCreateDialog(true);
    };

    

    const handleDelete = async (id) => {
        try {
            await deleteProduct({ variables: { id } });
            refetch();
        } catch (error) {
            console.error("Error deleting the product:", error);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const filteredProducts = data?.getAllProducts?.filter((product) =>
    product.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    product.categorie?.toLowerCase().includes(searchQuery.toLowerCase())
    // Ajoutez d'autres critères de filtrage si nécessaire
    ) || [];

    const productsToShow = filteredProducts.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

    return (
        <div>
            <Toolbar />
            <div className='Title'>
                <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                    <h2>Liste des produits</h2>
                    <Fab color="primary" aria-label="add" onClick={handleOpenCreateDialog}>
                        <AddIcon />
                    </Fab>
                </Box>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: 'center', width:'90%', margin:'auto', paddingBottom: '15px', paddingTop: '35px'}}>
                <TextField
                    label="Rechercher un produit ......"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <div className="dashboard-container">
                {/* <form onSubmit={handleSubmit} className="product-form">
                    <input className="form-input" name="name" value={formData.name} placeholder="Name"
                        onChange={handleInputChange} required/>
                    <input className="form-input" name="price" type="number" value={formData.price} placeholder="Price"
                        onChange={handleInputChange} required/>
                    <input className="form-input" name="quantite" type="number" value={formData.quantite}
                        placeholder="Quantity" onChange={handleInputChange} required/>
                    <select className="form-input" name="categorie" value={formData.categorie} onChange={handleInputChange}
                            required>
                        <option value="">Select Category</option>
                        <option value="bio">Bio</option>
                        <option value="commercialisé">Commercialisé</option>
                    </select>

                    <input className="form-input" name="image" value={formData.image} placeholder="Image URL"
                        onChange={handleInputChange} required/>
                    <button className="form-button" type="submit">{isEditing ? 'Update Product' : 'Create Product'}</button>
                </form> */}
                <table className="products-table">
                    <thead>
                        <tr>
                            <th style={{ borderRight: "1px solid white", textAlign: "center" }}>Nom du Produit</th>
                            <th style={{ borderRight: "1px solid white", textAlign: "center" }}>Prix</th>
                            <th style={{ borderRight: "1px solid white", textAlign: "center" }}>Quantité</th>
                            <th style={{ borderRight: "1px solid white", textAlign: "center" }}>Catégorie</th>
                            <th style={{ borderRight: "1px solid white", textAlign: "center" }}>Image</th>
                            <th style={{ borderRight: "1px solid white", textAlign: "center" }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {productsToShow.map((product) => (
                        <tr key={product.id}>
                            {/* <td style={{textAlign: "center"}}>{product.id}</td> */}
                            <td style={{textAlign: "center"}}>
                                {/* <img src={product.image} alt={product.name} style={{width: "50px", height: "auto"}}/>  */}
                                {product.name}
                            </td>
                            <td style={{textAlign: "center"}}>{product.price} €</td>
                            <td style={{textAlign: "center"}}>{product.quantite}</td>
                            <td style={{textAlign: "center"}}>{product.categorie}</td>
                            <td style={{display: "flex", justifyContent: "center"}} ><img src={product.image} alt={product.name} style={{width: "50px", height: "auto"}}/></td>
                            <td style={{textAlign: "center"}}>
                                <IconButton aria-label="modifier" color='primary' title="Modifier" onClick={() => handleUpdate(product)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton color='secondary' aria-label="supprimer" title="Supprimer" onClick={() => handleDelete(product.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <TablePagination
                    component="div"
                    count={filteredProducts.length} // Utilisez la longueur de filteredProducts ici
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={(event, newPage) => setCurrentPage(newPage)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setCurrentPage(0); // Reset to first page with new rowsPerPage
                    }}
                />
                <Toolbar />
            </div>
            <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog}>
                    <DialogTitle>{isEditing ? "Modifier le produit" : "Ajouter un nouveau produit"}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Nom du produit"
                            type="text"
                            fullWidth
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                        <TextField
                            margin="dense"
                            label="Prix"
                            type="number"
                            fullWidth
                            name="price"
                            value={formData.price}
                            onChange={handleInputChange}
                        />
                        <TextField
                            margin="dense"
                            label="Quantité"
                            type="number"
                            fullWidth
                            name="quantite"
                            value={formData.quantite}
                            onChange={handleInputChange}
                        />

                            <select name="categorie" value={formData.categorie} onChange={handleInputChange} style={{padding:'16px', borderRadius: '4px', width: '100%', marginTop: '7px'}}
                                required>
                            <option value="">Select Category</option>
                            <option value="bio">Bio</option>
                            <option value="commercialisé">Commercialisé</option>
                        </select>
                        <TextField
                            margin="dense"
                            label="Image URL"
                            type="Ttext"
                            fullWidth
                            name="image"
                            value={formData.image}
                            onChange={handleInputChange}
                        />

                </DialogContent>
                <DialogActions>
                    <Button className="form-button" onClick={handleCloseCreateDialog}>Annuler</Button>
                    <Button className="form-button" onClick={handleSubmit}>{isEditing ? 'Modifier' : 'Créer'}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ProductDashboard;
