//mutation.js

import { gql } from '@apollo/client';

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($firstName: String!, $lastName: String!, $email: String!, $mdp: String!) {
    createUser(firstName: $firstName, lastName: $lastName, email: $email, mdp: $mdp) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      id
      firstName
      lastName
      email
      profileImage
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: ID!, $firstName: String!, $lastName: String!, $email: String!,$profileImage: String) {
    updateUser(id: $id, firstName: $firstName, lastName: $lastName, email: $email, profileImage: $profileImage) {
      id
      firstName
      lastName
      email
      profileImage
    }
  }
`;
export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $mdp: String!) {
    login(email: $email, mdp: $mdp) {
      user {
        id
        firstName
        lastName
        age
        email
        profileImage
        mdp
        typeDeCheveux
        role
      }
      token
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query GetAllProducts {
    getAllProducts {
      id
      name
      price
      quantite
      image
      categorie
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduit($name: String!, $price: Float!, $quantite: Int!, $image: String!, $categorie: String) {
    createProduit(name: $name, price: $price, quantite: $quantite, image: $image, categorie: $categorie) {
      id
      name
      price
      quantite
      image
      categorie
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduit($id: ID!, $name: String, $price: Float, $quantite: Int, $image: String, $categorie: String) {
    updateProduit(id: $id, name: $name, price: $price, quantite: $quantite, image: $image, categorie: $categorie) {
      id
      name
      price
      quantite
      image
      categorie
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduit($id: ID!) {
    deleteProduit(id: $id)
  }
`;


export const GET_ALL_ROUTINES = gql`
  query GetAllRoutines {
    getAllRoutines {
      id
      name
      instructions
      startTime
      endTime
      duration
      type_hair
      produits {
        id
        name
        price
        quantite
        image
      }
    }
  }
`;

export const CREATE_ROUTINE = gql`
  mutation CreateRoutine($name: String!, $instructions: String!, $startTime: String!, $endTime: String!, $duration: Int!, $type_hair: String, $produits: [ID!]!) {
    createRoutine(name: $name, instructions: $instructions, startTime: $startTime, endTime: $endTime, duration: $duration, type_hair: $type_hair, produits: $produits) {
      id
      name
      instructions
      startTime
      endTime
      duration
      type_hair
      produits {
        id
        name
        price
        quantite
        image
      }
    }
  }
`;

export const UPDATE_ROUTINE = gql`
  mutation UpdateRoutine(
    $id: ID!,
    $name: String,
    $instructions: String,
    $startTime: String,
    $endTime: String,
    $duration: Int,
    $produits: [ID!]
  ) {
    updateRoutine(
      id: $id,
      name: $name,
      instructions: $instructions,
      startTime: $startTime,
      endTime: $endTime,
      duration: $duration,
      produits: $produits
    ) {
      id
      name
      instructions
      startTime
      endTime
      duration
      produits {
        id
        name
        price
        quantite
        image
      }
    }
  }
`;


export const DELETE_ROUTINE = gql`
  mutation DeleteRoutine($id: ID!) {
    deleteRoutine(id: $id)
  }
`;
