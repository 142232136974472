import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_USERS_ACTIVITY } from "../../graphql/queries";
import { Bar, Line, Pie } from 'react-chartjs-2';
import '../../styles/Apps-usage.css';
import {Toolbar} from '@mui/material';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
);

const AppsUsage = () => {
    const { loading, error, data } = useQuery(GET_ALL_USERS_ACTIVITY);
    const [activityByMonthChartData, setActivityByMonthChartData] = useState(null);
    const [activityByWeekChartData, setActivityByWeekChartData] = useState(null);
    const [activityByPageChartData, setActivityByPageChartData] = useState(null);

    useEffect(() => {
        if (!loading && data) {
            const filteredActivityData = data.getAllUsersActivity.filter(activity => activity.timeLoggedIn !== null);

            const filteredActivityDataPage = data.getAllUsersActivity.filter(activity => {
               return activity.year === 2023 && activity.pageVisited !== null
            });

            const pages = {};
            filteredActivityDataPage.forEach(activity => {
                const pageVisited = activity.pageVisited;
                pages[pageVisited] = (pages[pageVisited] || 0) + 1;
            });

            const pageNames = Object.keys(pages);
            const pageData = Object.values(pages);

            const monthlyActivityData = {};
            const weeklyActivityData = {};

            filteredActivityData.forEach(activity => {
                const monthYearKey = `${activity.month}-${activity.year}`;
                const dayOfWeekKey = `${activity.dayOfWeek}-${activity.month}`;

                // Agrégation par mois
                monthlyActivityData[monthYearKey] = (monthlyActivityData[monthYearKey] || 0) + 1;

                // Agrégation par semaine
                weeklyActivityData[dayOfWeekKey] = (weeklyActivityData[dayOfWeekKey] || 0) + 1;
            });

            
            // Transformer les données en tableaux pour les graphiques
            const months = Object.keys(monthlyActivityData);
            const monthData = Object.values(monthlyActivityData);
            const weeks = Object.keys(weeklyActivityData);
            const weekData = Object.values(weeklyActivityData);

            setActivityByMonthChartData({
                labels: months.map(month => {
                    const [monthNum, year] = month.split('-');
                    const monthName = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'][parseInt(monthNum) - 1];
                    return `${monthName}-${year}`;
                }),
                datasets: [{
                    label: 'Connexions par mois',
                    data: monthData,
                    backgroundColor: [
                        '#dd4477',
                        '#994499',
                        '#0099c6',
                        '#109618',
                        '#316395',
                        '#b82e2e',

                    ],
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                }],
            });

            setActivityByWeekChartData({
                labels: weeks.map(week => {
                    const [dayOfWeek, month] = week.split(/-/).map(item => item.trim());
                    /*console.log('dayOfWeek:', dayOfWeek);
                    console.log('month:', month);
                    console.log('year:', year);
                    const yearInt = parseInt(year);*/
                    const dayName = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'][parseInt(dayOfWeek)];
                    const monthName = ['Jan', 'Fév', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'][parseInt(month) - 1];
                    return `${dayName}-${monthName}`;
                }),
                datasets: [{
                    label: 'Connexions par semaine',
                    data: weekData,
                    backgroundColor: [
                        '#dd4477',
                        '#994499',
                        '#0099c6',
                        '#109618',
                        '#316395',
                        '#b82e2e',
                    ],
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 2,
                }],
            });
            setActivityByPageChartData({
                labels: pageNames,
                datasets: [{
                    label: 'Utilisateurs par pages visitées',
                    data: pageData,
                    backgroundColor: [
                        '#dd4477',
                        '#994499',
                        '#0099c6',
                        '#109618',
                        '#316395',
                        '#FF5733',
                        '#466A54',
                        '#955A10',
                        '#0A636F',
                        '#9A2759',
                    ],

                    borderColor: [
                        '#dd4477',
                        '#994499',
                        '#0099c6',
                        '#109618',
                        '#316395',
                        '#FF5733',
                        '#466A54',
                        '#955A10',
                        '#0A636F',
                        '#9A2759',
                    ],
                    borderWidth: 1,
                }],
            });

        }
    }, [loading, data]);

    if (loading) return <p>Chargement...</p>;
    if (error) return <p>Erreur : {error.message}</p>;

    return (
        <div>
            <Toolbar />
            <div className=''>
                <h1 style={{padding: '20px 20px'}}>Activités du Dashboard</h1>
            </div>
            <div className='container'>
                <div className="chart-container">
                    {activityByMonthChartData && (
                        <Bar
                            data={activityByMonthChartData}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        position: 'top',
                                    },
                                    title: {
                                        display: true,
                                        text: 'Activité par mois',
                                    },
                                },
                            }}
                        />
                    )}
                </div>
                <div className="pie-container">
                {activityByPageChartData && (
                    <Pie
                        data={activityByPageChartData}
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'bottom',
                                    radius: 10,
                                },
                                title: {
                                    display: true,
                                    text: 'Utilisateurs par pages visitées',
                                },
                            },
                        }}
                        
                    />
                )}
                </div>
            </div>
            <div className='container2'>
                <div className="line-container">
                        {activityByWeekChartData && (
                            <Line
                                data={activityByWeekChartData}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: 'Activité par semaine',
                                        },
                                    },
                                }}
                            />
                        )}
                </div>
            </div>
        </div>
    );
};


export default AppsUsage;
