//queries.js

import gql from 'graphql-tag';

export const ME_QUERY = gql`
    query Me {
        me {
            user {
                id
                mongoId
                firstName
                lastName
                age
                email
                profileImage
                mdp
                typeDeCheveux
                role
            }
        }
    }
`;

export const CONVERSATIONS_QUERY = gql`
    query Conversations($userId: ID!) {
        conversations(userId: $userId) {
            id
            participants {
                id
                firstName
                lastName
                profileImage
                role
            }
            messages {
                id
                content
                timestamp
                read
                sender {
                    id
                    firstName
                    lastName
                    role
                }
                readBy {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;


export const ALL_CONVERSATIONS_QUERY = gql`
    query AllConversations {
        allConversations {
            id
            participants {
                id
                firstName
                lastName
                profileImage
            }
            messages {
                id
                content
                timestamp
                read
                sender {
                    id
                    firstName
                    lastName
                }
                readBy {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const MESSAGES_QUERY = gql`
    query Messages($conversationId: ID!) {
        messages(conversationId: $conversationId) {
            id
            content
            timestamp
            sender {
                id
                mongoId
                firstName
                lastName
                role
            }
        }
    }
`;

export const GET_MESSAGES_QUERY = gql`
    query GetMessages($conversationId: ID!) {
        messages(conversationId: $conversationId) {
            id
            content
            timestamp
            read
            readBy {
                id
                firstName
                lastName
                mongoId
            }
            sender {
                id
                firstName
                lastName
                mongoId
            }
        }
    }
`;


export const CREATE_MESSAGE_MUTATION = gql`
    mutation CreateMessage($conversationId: ID!, $content: String!) {
        createMessage(conversationId: $conversationId, content: $content) {
            id
            content
            timestamp
            sender {
                id
                mongoId
                firstName
                lastName
                role
            }
        }
    }
`;

export const GET_ALL_USERS_ACTIVITY = gql`
  query {
    getAllUsersActivity {
        id
        date
        timeLoggedIn
        day
        month
        year
        pageVisited
      
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      id
      firstName
      lastName
      email
      profileImage
      typeDeCheveux
    }
  }
`;

