//Messagerie.js

import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { List, ListItem, CircularProgress, Paper, Box, ListItemAvatar, Avatar, ListItemText, Typography } from '@mui/material';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ALL_CONVERSATIONS_QUERY } from '../../graphql/queries';
import { useNavigate } from 'react-router-dom';


const Messagerie = ({ onSelectConversation }) => {

    const [authHeader, setAuthHeader] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const token = await AsyncStorage.getItem('token');
                if (token) {
                    setAuthHeader(`Bearer ${token}`);
                }
            } catch (error) {
                console.error('Failed to fetch token:', error);
            }
        };

        fetchToken();
    }, []);

    const { loading, error, data } = useQuery(ALL_CONVERSATIONS_QUERY, {
        context: {
            headers: {
                authorization: authHeader, // Ensure this header is being sent
            },
        },
        skip: !authHeader, // Skip query until authHeader is set
    });

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <p style={{
            marginTop: '12rem', padding: '12rem'
        }}>Error :(</p>;
    }

    if (!data || data.allConversations.length === 0) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="h6" component="div">
                    Aucun message trouvé
                </Typography>
            </Box>
        );
    }


    const handleSelectConversation = (conversation) => {
        if (!conversation || !conversation.user) {
            console.error('Invalid conversation or user data:', conversation);
            return;
        }

        const userName = `${conversation.user.firstName} ${conversation.user.lastName}`;
        console.log('Navigating to discussion with:', conversation, userName);

        navigate(`/discussion/${conversation.user.id}`, {
            state: {
                conversationId: conversation.user.id,
                userName: userName
            }
        });
    };



    // Group messages by user
    const userMessagesMap = groupMessagesByUser(data.allConversations);
    console.log('User messages map:', userMessagesMap); // Log the grouped messages

    // Get last messages for each user
    const lastMessages = getLastMessagesByUser(userMessagesMap);
    console.log('Last messages by user:', lastMessages); // Log the last messages for each user


    return (
        <Paper>
            <List>
                {lastMessages.map(({ user, lastMessage }) => (
                    <ListItem
                        button
                        key={user.id}
                        onClick={() => {
                            console.log('Selected user messages:', userMessagesMap[user.id]);
                            handleSelectConversation(userMessagesMap[user.id]);
                        }}
                    >


                        <ListItemAvatar>
                            <Avatar src={user.profileImage || "/default-profile.png"} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${user.firstName} ${user.lastName}`}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                    >
                                        {user.firstName}:
                                    </Typography>
                                    {` ${lastMessage.content}`}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
}

const groupMessagesByUser = (conversations) => {
    console.log('Grouping messages by user'); // Log the grouping process start
    const userMessagesMap = {};

    conversations.forEach(conversation => {
        conversation.messages.forEach(message => {
            const userId = message.sender.id;
            if (!userMessagesMap[userId]) {
                userMessagesMap[userId] = {
                    user: message.sender,
                    messages: []
                };
            }
            userMessagesMap[userId].messages.push(message);
        });
    });

    console.log('Grouped messages by user:', userMessagesMap); // Log the final grouped messages
    return userMessagesMap;
};


const getLastMessagesByUser = (userMessagesMap) => {
    console.log('Getting last messages by user'); // Log the process start
    const lastMessages = Object.keys(userMessagesMap).map(userId => {
        const userMessages = userMessagesMap[userId];
        const lastMessage = userMessages.messages[userMessages.messages.length - 1];
        return {
            user: userMessages.user,
            lastMessage: lastMessage,
        };
    });

    console.log('Final last messages list:', lastMessages); // Log the final list of last messages
    return lastMessages;
};

export default Messagerie;
