import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Select from 'react-select';
import {
    Box, Button, TextField, Card, CardContent, Typography, Grid,
    CardActions, Toolbar, Dialog, DialogActions, DialogContent,
    DialogTitle, Fab, IconButton, Pagination,  DialogContentText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { GET_ALL_ROUTINES, GET_ALL_PRODUCTS, CREATE_ROUTINE, UPDATE_ROUTINE, DELETE_ROUTINE } from '../../graphql/mutations';
import '../../styles/Routine.css';


const RoutineDashboard = () => {
    const { loading, error, data, refetch } = useQuery(GET_ALL_ROUTINES);
    const [createRoutine] = useMutation(CREATE_ROUTINE, { onCompleted: () => refetch() });
    const [updateRoutine] = useMutation(UPDATE_ROUTINE, { onCompleted: () => refetch() });
    const [deleteRoutine] = useMutation(DELETE_ROUTINE, { onCompleted: () => refetch() });

    // Ajout de la gestion de l'état de chargement et des erreurs pour les produits
    const { loading: loadingProducts, error: errorProducts, data: productsData } = useQuery(GET_ALL_PRODUCTS);

    // Initialisation de l'état du formulaire
    const [formData, setFormData] = useState({
        name: '',
        instructions: '',
        type_hair: '',
        minAge: 0,
        duration: 0,
        produits: [],
    });

    // Autres états
    const [editingId, setEditingId] = useState(null);
    const [productOptions, setProductOptions] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteCandidate, setDeleteCandidate] = useState(null);
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;

    const handleEdit = routine => {
        setEditingId(routine.id);
        setFormData({
            name: routine.name,
            instructions: routine.instructions,
            type_hair: routine.type_hair,
            minAge: routine.minAge,
            duration: routine.duration,
            produits: routine.produits.map(p => p.id),
        });
        setModalOpen(true);
    };

    useEffect(() => {
        // Correction de la vérification pour s'assurer que productsData.getAllProducts est défini et est un tableau
        if (productsData && Array.isArray(productsData.getAllProducts)) {
            setProductOptions(productsData.getAllProducts.map(product => ({
                value: product.id,
                label: product.name
            })));
        }
    }, [productsData]);

    if (loading || loadingProducts) return <p>Loading...</p>;
    if (error) return <p style={{
        marginTop: '12rem', padding: '12rem'
    }}>Error loading routines: {error.message}</p>;
    if (errorProducts) return <p>Error loading products: {errorProducts.message}</p>;


    const filteredRoutines = data ? data.getAllRoutines.filter(routine =>
        routine.name.toLowerCase().includes(filter.toLowerCase())
    ) : [];

    const paginatedRoutines = filteredRoutines.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    // console.log(paginatedRoutines)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Gestion des changements de sélection des produits
    const handleProductChange = selectedOptions => {
        // Mise à jour de l'état avec les IDs des produits sélectionnés
        const productIds = selectedOptions.map(option => option.value);
        setFormData({ ...formData, produits: productIds });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const startTime = new Date().toISOString(); // Utilisez une date de début actuelle en format ISO
    
        const endTime = new Date(new Date().getTime() + formData.duration * 60000).toISOString(); // Calculer l'endTime basé sur la durée
    
        const variables = {
            ...formData,
            startTime, // Ajoutez startTime
            endTime, // Ajoutez endTime
            produits: formData.produits,  // IDs des produits sélectionnés
            duration: parseInt(formData.duration, 10), // Convertir la durée en entier
            minAge: parseInt(formData.minAge, 10), // Convertir la durée en entier
        };
    
        try {
            const mutation = editingId ? updateRoutine : createRoutine;
            await mutation({
                variables: editingId ? { id: editingId, ...variables } : variables
            });
            closeModal();
            resetForm();
        } catch (error) {
            console.error("Erreur lors de la gestion de la routine :", error);
        }
    };    





    const handleDeleteConfirmation = async () => {
        await deleteRoutine({ variables: { id: deleteCandidate } });
        setDeleteDialogOpen(false);
        setDeleteCandidate(null);
    };

    const resetForm = () => {
        setFormData({
            name: '',
            instructions: '',
            type_hair: '',
            duration: 0,
            produits: [],
        });
        setEditingId(null);
    };

    const openModal = () => {
        setModalOpen(true);
        resetForm();
    };
    const closeModal = () => setModalOpen(false);
    const openDeleteDialog = (id) => {
        setDeleteDialogOpen(true);
        setDeleteCandidate(id);
    };

    if (loading) return <p style={{
        marginTop: '12rem', padding: '12rem'
    }}>Loading...</p>;
    if (error) return <p style={{
        marginTop: '12rem', padding: '12rem'
    }}>Error :( Please try reloading the page.</p>;

    return (
        <>

            <Toolbar />
                    <div className='Title'>
                        <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                            <h2>Tableau de bord des
                                routines</h2>
                            <Fab color="primary" aria-label="add" onClick={openModal}>
                                <AddIcon/>
                            </Fab>
                        </Box>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: 'center',
                        width: '90%',
                        margin: 'auto',
                        paddingBottom: '15px',
                        paddingTop: '35px'
                    }}>
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Rechercher..."
                            InputProps={{
                                endAdornment: <IconButton><SearchIcon/></IconButton>,
                            }}
                            onChange={e => setFilter(e.target.value)}
                        />
                    </div>
            <div className="dashboard-container">
            <Grid container spacing={2} style={{marginTop: 20}}>
                    {paginatedRoutines.map(routine => (
                        <Grid item xs={12} sm={6} md={4} key={routine.id}>
                            <Card>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    {routine.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Instructions: {routine.instructions}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Durée: {routine.duration} minutes
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Type de cheveux: {routine.type_hair}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Produits: {routine.produits.map(p => p.name).join(', ')}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" color="primary" onClick={() => handleEdit(routine)}>
                                    <EditIcon /> Modifier
                                </Button>
                                <Button size="small" color="secondary" onClick={() => openDeleteDialog(routine.id)}>
                                    <DeleteIcon /> Supprimer
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Box my={2} display="flex" justifyContent="center">
                <Pagination
                    count={Math.ceil(filteredRoutines.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(e, page) => setCurrentPage(page)}
                    color="primary"
                />
            </Box>

            </div>
            <Dialog open={modalOpen} onClose={closeModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{editingId ? 'Modifier la routine' : 'Créer une routine'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nom de la routine"
                        type="text"
                        fullWidth
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        id="instructions"
                        label="Instructions"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        name="instructions"
                        value={formData.instructions}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        id="type_hair"
                        label="Type de cheveux"
                        type="text"
                        fullWidth
                        name="type_hair"
                        value={formData.type_hair}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        id="minAge"
                        label="Age minimum"
                        type="number"
                        fullWidth
                        name="minAge"
                        value={formData.minAge}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        id="duration"
                        label="Durée (en minutes)"
                        type="number"
                        fullWidth
                        name="duration"
                        value={formData.duration.toString()}
                        onChange={handleInputChange}
                    />
                    <Select
                        options={productOptions}
                        isMulti
                        name="produits"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleProductChange}
                        value={productOptions.filter(option => formData.produits.includes(option.value))}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color="primary">Annuler</Button>
                    <Button onClick={handleSubmit} color="primary">{editingId ? 'Mettre à jour' : 'Créer'}</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle id="alert-dialog-title">Confirmer la suppression?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Cette action est irréversible. Êtes-vous sûr de vouloir supprimer cette routine ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">Annuler</Button>
                    <Button onClick={handleDeleteConfirmation} color="primary" autoFocus>Supprimer</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RoutineDashboard;
