import React from 'react';
import { Box, Typography, Paper, Divider } from '@material-ui/core';

const PrivacyPage = () => {
    return (
        <Box style={{ maxWidth: '800px', margin: 'auto', padding: '20px' }}>
            <Typography variant="h4" gutterBottom style={{ textAlign: 'center', marginBottom: '30px' }}>
                Politique de confidentialité de Groomed Hair
            </Typography>
            <Paper style={{ padding: '30px', backgroundColor: '#f9f9f9', borderRadius: '10px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                <Typography paragraph variant="body1">
                    Chez Groomed Hair, nous nous engageons à protéger et respecter votre vie privée. Cette politique de confidentialité explique comment nous
                    utilisons et protégeons les informations que vous nous fournissez lorsque vous utilisez notre site web ou notre application mobile.
                </Typography>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h5" gutterBottom>
                    Collecte et utilisation des informations
                </Typography>
                <Typography paragraph>
                    Nous recueillons des informations personnelles telles que votre nom, votre adresse e-mail et vos préférences lorsque vous créez un compte
                    sur Groomed Hair. Ces informations sont utilisées pour personnaliser votre expérience utilisateur et améliorer nos services.
                </Typography>
                <Typography paragraph>
                    Nous utilisons également des cookies et des technologies similaires pour suivre votre activité sur notre site et recueillir des informations
                    sur votre comportement en ligne. Cela nous aide à fournir un contenu personnalisé et à analyser la performance de notre site.
                </Typography>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h5" gutterBottom>
                    Partage des informations
                </Typography>
                <Typography paragraph>
                    Nous ne partageons pas vos informations personnelles avec des tiers sans votre consentement, sauf si cela est nécessaire pour fournir nos services
                    ou si nous sommes légalement tenus de le faire.
                </Typography>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h5" gutterBottom>
                    Sécurité des données
                </Typography>
                <Typography paragraph>
                    Nous prenons des mesures techniques et organisationnelles pour protéger vos informations personnelles contre tout accès non autorisé, utilisation
                    abusive ou divulgation. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée.
                </Typography>
                <Typography paragraph>
                    Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité, n'hésitez pas à nous contacter.
                </Typography>
            </Paper>
        </Box>
    );
};

export default PrivacyPage;
